import React from "react";
import { classNames } from "@ct-react/core";
import { SkeletonableProps } from "../../../tools/components";
import { ArticleSummary } from "../../../models/article";
import { Ratio } from "../../../models/images";
import RatioImage from "../../common/ratio-image";
import ResumeFeatures from "../../cards/resume-features";
import "./article-preview.scss";

type BookingArticlePreviewProps = SkeletonableProps<ArticleSummary<string>>;

const BookingArticlePreview = (
  {
    loading,
    className,
    data
  }: BookingArticlePreviewProps) => {

  if (loading)
    return null;

  const wrapperClasses = classNames("rla-book-preview", className);

  return (
    <div className={wrapperClasses}>
      <RatioImage images={data!.images.find(i => i.main)!.assets}
                  ratio={Ratio.SIXTEEN_NINE}
                  alt={data!.title.value} />
      <ResumeFeatures {...data!.features} />
    </div>);

};

export default BookingArticlePreview;
